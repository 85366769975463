<template>
  <div class="cmp-wrapper" :class="[isDark && 'cmp-wrapper--dark']">
    <navbar msg="تراکنش ‌ها" />
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading'"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <section class="load-resolved" v-else-if="pageStatus == 'resolved'">
      <div :class="['receiver', isDark && 'receiver--dark']">
        <div class="receiver__title">
          <h1 class="receiver__number">پذیرنده {{ receiver['cashier_id'] }}</h1>
          <h2 class="receiver__name">{{ receiver['name'] }}</h2>
        </div>
      </div>
      <!-- <div :class="['receiver-count', isDark && 'count--dark']">
        <h5 class="count__title">ثبت مشتری</h5>
        <h6 class="count__number" v-text="receiver['stat']['customer']" />
      </div> -->
      <div :class="['table', isDark && 'table--dark']">
        <router-link
          :to="{
            name: 'ReceiverTransaction',
            params: { id },
            query: { type: '1' }
          }"
          class="table__row"
        >
          <h4 class="table__title">ثبت فروش</h4>
          <h5
            class="table__amount amount--rial"
            :class="receiver['stat']['sell'] !== 0 && 'amount--positive'"
          >
            {{ receiver['stat']['sell'].toLocaleString() }}
          </h5>
          <svg class="table__arrow" viewBox="0 0 512 512">
            <path
              d="M174.6 252.89l205.16 -205.16c7.2,-7.21 7.2,-18.89 0,-26.1 -7.2,-7.2 -18.88,-7.2 -26.09,0l-221.43 221.44c-7.2,7.2 -7.2,18.88 0,26.09l221.43 221.43c7.33,7.08 19.01,6.87 26.09,-0.46 6.91,-7.15 6.91,-18.48 0,-25.63l-205.13 -205.13 0 -0.03c-0.82,-0.83 -1.33,-1.97 -1.33,-3.23 0,-1.26 0.51,-2.4 1.33,-3.22l-0.03 0z"
            />
          </svg>
        </router-link>
        <i class="table__hr" />
        <router-link
          :to="{
            name: 'ReceiverTransaction',
            params: { id },
            query: { type: '5' }
          }"
          class="table__row"
        >
          <h4 class="table__title">دریافت اعتبار خرید</h4>
          <h5
            class="table__amount amount--rial"
            :class="receiver['stat']['credit'] !== 0 && 'amount--positive'"
          >
            {{ receiver['stat']['credit'].toLocaleString() }}
          </h5>
          <svg class="table__arrow" viewBox="0 0 512 512">
            <path
              d="M174.6 252.89l205.16 -205.16c7.2,-7.21 7.2,-18.89 0,-26.1 -7.2,-7.2 -18.88,-7.2 -26.09,0l-221.43 221.44c-7.2,7.2 -7.2,18.88 0,26.09l221.43 221.43c7.33,7.08 19.01,6.87 26.09,-0.46 6.91,-7.15 6.91,-18.48 0,-25.63l-205.13 -205.13 0 -0.03c-0.82,-0.83 -1.33,-1.97 -1.33,-3.23 0,-1.26 0.51,-2.4 1.33,-3.22l-0.03 0z"
            />
          </svg>
        </router-link>
        <i class="table__hr" />
        <router-link
          :to="{
            name: 'ReceiverTransaction',
            params: { id },
            query: { type: '3' }
          }"
          class="table__row"
        >
          <h4 class="table__title">دریافت بن خرید</h4>
          <h5
            class="table__amount amount--rial"
            :class="receiver['stat']['bon'] !== 0 && 'amount--positive'"
          >
            {{ receiver['stat']['bon'].toLocaleString() }}
          </h5>
          <svg
            class="table__arrow"
            width="512px"
            height="512px"
            viewBox="0 0 512 512"
          >
            <path
              d="M174.6 252.89l205.16 -205.16c7.2,-7.21 7.2,-18.89 0,-26.1 -7.2,-7.2 -18.88,-7.2 -26.09,0l-221.43 221.44c-7.2,7.2 -7.2,18.88 0,26.09l221.43 221.43c7.33,7.08 19.01,6.87 26.09,-0.46 6.91,-7.15 6.91,-18.48 0,-25.63l-205.13 -205.13 0 -0.03c-0.82,-0.83 -1.33,-1.97 -1.33,-3.23 0,-1.26 0.51,-2.4 1.33,-3.22l-0.03 0z"
            />
          </svg>
        </router-link>
        <i class="table__hr" />
        <router-link
          :to="{
            name: 'ReceiverTransaction',
            params: { id },
            query: { type: '6' }
          }"
          class="table__row"
        >
          <h4 class="table__title">دریافت بونوس</h4>
          <h5
            class="table__amount amount--rial"
            :class="receiver['stat']['bonus'] !== 0 && 'amount--positive'"
          >
            {{ receiver['stat']['bonus'].toLocaleString() }}
          </h5>
          <svg class="table__arrow" viewBox="0 0 512 512">
            <path
              d="M174.6 252.89l205.16 -205.16c7.2,-7.21 7.2,-18.89 0,-26.1 -7.2,-7.2 -18.88,-7.2 -26.09,0l-221.43 221.44c-7.2,7.2 -7.2,18.88 0,26.09l221.43 221.43c7.33,7.08 19.01,6.87 26.09,-0.46 6.91,-7.15 6.91,-18.48 0,-25.63l-205.13 -205.13 0 -0.03c-0.82,-0.83 -1.33,-1.97 -1.33,-3.23 0,-1.26 0.51,-2.4 1.33,-3.22l-0.03 0z"
            />
          </svg>
        </router-link>
        <i class="table__hr" />
        <!-- <router-link
          :to="{
            name: 'ReceiverTransaction',
            params: { id },
            query: { type: '2' }
          }"
          class="table__row"
        >
          <h4 class="table__title">دریافت آفر</h4>
          <h5
            class="table__amount amount--rial"
            :class="receiver['stat']['offer'] !== 0 && 'amount--positive'"
          >
            {{ receiver['stat']['offer'].toLocaleString() }}
          </h5>
          <svg class="table__arrow" viewBox="0 0 512 512">
            <path
              d="M174.6 252.89l205.16 -205.16c7.2,-7.21 7.2,-18.89 0,-26.1 -7.2,-7.2 -18.88,-7.2 -26.09,0l-221.43 221.44c-7.2,7.2 -7.2,18.88 0,26.09l221.43 221.43c7.33,7.08 19.01,6.87 26.09,-0.46 6.91,-7.15 6.91,-18.48 0,-25.63l-205.13 -205.13 0 -0.03c-0.82,-0.83 -1.33,-1.97 -1.33,-3.23 0,-1.26 0.51,-2.4 1.33,-3.22l-0.03 0z"
            />
          </svg>
        </router-link>
        <i class="table__hr" /> -->
      </div>
      <div class="table__row final--row">
        <h4 class="table__title">صندوق باز</h4>
        <h5
          class="table__amount amount--rial"
          :class="receiver['stat']['total'] !== 0 && 'amount--positive'"
        >
          {{ receiver['stat']['total'].toLocaleString() }}
        </h5>
      </div>
      <div class="credit-buttons">
        <button
          type="button"
          class="button button--top"
          @click="closeReceiverChest(receiver['id'])"
        >
          <span class="button__text">بستن صندوق</span>
          <spinner v-if="btnLoading" />
        </button>
        <router-link
          :to="{ name: 'ReceiverCheckout', params: { id } }"
          class="button button--bottom"
        >
          <span class="button__text">
            صندوق های بسته شده
          </span>
        </router-link>
      </div>
      <div
        :class="['active-time', isDark && 'acite-time--dark']"
        v-if="receiver['cashier_id'] != 1"
      >
        <h5 class="active-time__time" v-text="expDay < 0 ? 0 : expDay" />
        <span style="font-size: 14px">روز باقی مانده</span>
      </div>
      <section
        :class="['customer-btns', isDark && 'btns--dark']"
        v-if="receiver['cashier_id'] != 1"
      >
        <router-link
          :to="{
            name: 'ReceiverNew',
            params: { mode: 'edit', id: receiver['id'] }
          }"
          class="customer-btn"
        >
          <svg
            class="customer-btn__icon"
            style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
            viewBox="0 0 700 512"
          >
            <path
              d="M280.92 323.54l88.44 7.05 214.11 -214.12c26.28,-26.29 26.28,-69.34 0,-95.62 -26.29,-26.29 -69.34,-26.29 -95.63,0l-214.11 214.1 7.19 88.59zm27.66 -27.71l-4.02 -49.55 204.35 -204.36c14.65,-14.65 38.83,-14.65 53.48,0 14.66,14.65 14.66,38.82 0,53.47l-204.39 204.39 -49.42 -3.95zm275.06 -108.67l-24.6 24.6c3.83,16.39 5.74,33.18 5.74,50.02 0,120.87 -97.98,218.85 -218.86,218.85 -120.87,0 -218.87,-97.98 -218.87,-218.85 0,-120.88 98,-218.87 218.87,-218.87 14.93,0 29.83,1.52 44.45,4.54l24.95 -24.96c-22.55,-6.52 -45.89,-9.81 -69.4,-9.81 -137.57,0 -249.1,111.52 -249.1,249.1 0,137.57 111.53,249.09 249.1,249.09 137.57,0 249.1,-111.52 249.1,-249.09 0,-25.34 -3.81,-50.48 -11.38,-74.62z"
            />
          </svg>
          <span class="customer-btn__text">ویرایش</span>
        </router-link>
        <router-link
          :to="{
            name: 'ReceiverNew',
            params: { mode: 'recharge', id: receiver['id'] }
          }"
          class="customer-btn"
        >
          <svg
            class="customer-btn__icon"
            viewBox="0 0 512 512"
            style="wdith: 27px;height: 27px"
          >
            <path
              d="M493.815,70.629c-11.001-1.003-20.73,7.102-21.733,18.102l-2.65,29.069C424.473,47.194,346.429,0,256,0
			C158.719,0,72.988,55.522,30.43,138.854c-5.024,9.837-1.122,21.884,8.715,26.908c9.839,5.024,21.884,1.123,26.908-8.715
			C102.07,86.523,174.397,40,256,40c74.377,0,141.499,38.731,179.953,99.408l-28.517-20.367c-8.989-6.419-21.48-4.337-27.899,4.651
			c-6.419,8.989-4.337,21.479,4.651,27.899l86.475,61.761c12.674,9.035,30.155,0.764,31.541-14.459l9.711-106.53
			C512.919,81.362,504.815,71.632,493.815,70.629z"
            />
            <path
              d="M472.855,346.238c-9.838-5.023-21.884-1.122-26.908,8.715C409.93,425.477,337.603,472,256,472
			c-74.377,0-141.499-38.731-179.953-99.408l28.517,20.367c8.989,6.419,21.479,4.337,27.899-4.651
			c6.419-8.989,4.337-21.479-4.651-27.899l-86.475-61.761c-12.519-8.944-30.141-0.921-31.541,14.459l-9.711,106.53
			c-1.003,11,7.102,20.73,18.101,21.733c11.014,1.001,20.731-7.112,21.733-18.102l2.65-29.069C87.527,464.806,165.571,512,256,512
			c97.281,0,183.012-55.522,225.57-138.854C486.594,363.309,482.692,351.262,472.855,346.238z"
            />
          </svg>
          <span class="customer-btn__text">تمدید</span>
        </router-link>
      </section>
    </section>
    <failed-mode
      style="margin-top: 70px;"
      @refresh="$store.dispatch('receiver/fetchItem', { part: 'receiver', id })"
      v-else
    />
    <fixed-footer />
  </div>
</template>

<script>
import { computed, onBeforeUnmount, ref } from 'vue'
import { useStore } from 'vuex'
import { closeChest } from '@service/ReceiverService'
import dateConvertor from '@composable/dateConvertor'

export default {
  name: 'ReceiverShow',
  props: ['id'],
  setup(props) {
    // Get receiver
    const store = useStore()
    const receiver = computed(() => store.state.receiver.item)
    const pageStatus = computed(() => store.state.receiver.pageStatus)
    const isDark = computed(() => store.state.receiver.isDark)
    const { getRemainingDay } = dateConvertor()

    // Initail API call
    store.dispatch('receiver/fetchItem', { part: 'cashier', id: props.id })

    // Clean the room
    onBeforeUnmount(() => store.dispatch('receiver/clearItem'))

    const expDay = computed(() => {
      if (!receiver.value) return 0
      const expDate = new Date(receiver.value['subscription'])
      const now = new Date()

      return Math.floor((expDate - now) / (1000 * 60 * 60 * 24))
    })

    // Closing receiver chest
    const btnLoading = ref(null)
    const closeReceiverChest = async (id) => {
      if (btnLoading.value) return
      if (receiver.value['stat']['total'] === 0)
        return store.dispatch('addToast', {
          type: 'error',
          message: 'صندوق 0 می باشد.'
        })
      btnLoading.value = true
      try {
        await closeChest(id)
        store.dispatch('addToast', {
          type: 'success',
          message: 'صندوق با موفقیت بسته شد.'
        })
        store.dispatch('fetchHomeData')
        store.dispatch('receiver/fetchItem', { part: 'cashier', id: props.id })
      } catch (ex) {
        if (ex.response) {
          store.dispatch('addToast', {
            type: 'status',
            status: ex.response.status
          })
        }
      } finally {
        btnLoading.value = false
      }
    }

    return {
      getRemainingDay,
      receiver,
      expDay,
      pageStatus,
      closeReceiverChest,
      btnLoading,
      isDark
    }
  }
}
</script>

<style lang="scss" scoped>
.receiver-count {
  border-radius: 15px 15px 0 0;
  padding: 0px 0 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .count__title {
    text-align: right;
    font-size: 16px;
    color: #222;
    margin: 0;
  }
  .count__number {
    text-align: right;
    font-size: 16px;
    margin: 0 5px 0 0;
    color: #222;
    &::after {
      content: 'نفر';
      margin-right: 5px;
      color: #333;
    }
  }
}
.count--dark {
  .count__title {
    color: #777;
  }
  .count__number {
    color: #777;
    &::after {
      color: #555;
    }
  }
}
/** Table Block  */
.table {
  border: none;
  padding: 0 10px;
  margin: 0 0 8px;
  background-color: #fff;
}
.table__row {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr) 30px;
  height: 60px;
}
.table__title {
  justify-self: start;
  font-size: 14px;
  text-align: right;
  padding: 2px 5px 0 0;
  color: #111;
  margin: 0;
}
.table__amount {
  justify-self: end;
  line-height: 30px;
  font-size: 16px;
  text-align: center;
  color: #111;
  margin: 0 0 0 18px;
  padding-top: 3px;
  &.amount--rial {
    position: relative;
    &::after {
      color: #111;
      position: absolute;
      content: 'R';
      font-size: 10px;
      right: -8px;
      top: 0px;
    }
  }
  &.amount--positive {
    position: relative;
    &::before {
      color: #111;
      position: absolute;
      content: '+';
      font-size: 18px;
      left: -16px;
    }
  }
  &.amount--negative {
    @extend .amount--positive;
    &::before {
      content: '-';
      left: -14px;
      font-size: 20px;
    }
  }
}
.table__arrow {
  width: 20px;
  height: 20px;
  fill: #bbb;
  justify-self: end;
}
.table__hr {
  height: 1px;
  width: 100%;
  background-color: #bbb;
  &:last-child {
    display: none;
  }
}

.table--dark {
  background-color: #131313;
  .table__row .table__title {
    color: #555;
  }
  .table__row .table__amount {
    color: #555;
  }
  .table__hr {
    background-color: #1c1c1c;
  }
  .table__row .amount--rial::after {
    color: #555;
  }
  .table__arrow {
    fill: #444;
  }
}

.final--row {
  width: calc(96% - 20px);
}
.cmp-wrapper--dark .final--row {
  .table__title {
    color: #777;
  }
  .table__amount {
    color: #777;
    &::before,
    &::after {
      color: #555;
    }
  }
}
.customer-btns {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 0 50px;
  padding: 0;
}
.customer-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e5e5e5;
  cursor: pointer;
  position: relative;
  outline: none;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  margin: 0 5px;
}
.customer-btn__text {
  color: #000;
  display: inline-block;
  position: absolute;
  width: 100px;
  text-align: center;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  // right: 0;
  // margin: 0 auto;
  font-size: 12px;
}
.customer-btn__icon {
  width: 35px;
  height: 35px;
  fill: #555;
}
.btns--dark {
  .customer-btn {
    background-color: #131313;
  }
  .customer-btn__text {
    color: #777;
  }
}

.active-time {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 15px;
  height: 100px;
  animation: blinking 1.3s infinite;
}
.active-time__time {
  position: relative;
  font-size: 20px;
  text-align: center;
}
.acite-time--dark {
  color: #555;
  .active-time__time {
    color: #555;
  }
}

.receiver {
  padding: 60px 10px 0;
  margin: 0 0 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ccc;
  border-radius: 0 0 30px 30px;
}
.receiver__title {
  height: 80px;
  width: 100%;
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
}
.receiver__number {
  text-align: right;
  font-size: 16px;
  color: #444;
  // margin-bottom: 10px;
}
.receiver__name {
  text-align: right;
  font-size: 16px;
  color: #444;
  margin-right: 5px;
}
.receiver--dark {
  background-color: rgba(#101010, 0.4);
}
.receiver--dark .receiver__number {
  color: #555;
  font-weight: normal;
}
.receiver--dark .receiver__name {
  color: #555;
}

// change in common css
.cmp-wrapper {
  padding-top: 0;
}
.credit-buttons {
  flex-direction: column;
  justify-content: flex-start;
  width: 96%;
  .button {
    width: 100%;
  }
  .button--top {
    margin-bottom: 15px;
    background-color: #c69d4e;
  }
  .button--bottom {
    background-color: rgba(#000, 0.7);
    .button__text {
      color: #fff;
    }
  }
}
.cmp-wrapper--dark .credit-buttons {
  .button--top {
    margin-bottom: 15px;
    background-color: #c69d4e;
  }
  .button--bottom {
    background-color: #131313;
    .button__text {
      color: #555;
    }
  }
}
</style>
