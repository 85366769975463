<template>
  <div class="cmp-wrapper">
    <navbar msg="صندوق بسته شده" />
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading'"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <failed-mode
      style="margin-top: 70px;"
      @refresh="getCheckout"
      v-else-if="pageStatus == 'failed'"
    />
    <section class="load-resolved" v-else>
      <div class="checkout-time">
        <h5>
          {{ getHour(checkout['created_at']) }}
        </h5>
        <h4>
          {{ localizeDate(checkout['created_at']) }}
        </h4>
      </div>
      <!-- <div class="receiver-count">
        <h5 class="count__title">ثبت مشتری</h5>
        <h6 class="count__number" v-text="checkout['customer']" />
      </div> -->
      <div class="table">
        <router-link
          :to="{
            name: 'ReceiverTransaction',
            params: { id },
            query: { type: '1', checkout: checkoutId }
          }"
          class="table__row"
        >
          <h4 class="table__title">ثبت فروش</h4>
          <h5
            class="table__amount amount--rial"
            :class="checkout['sell'] !== 0 && 'amount--positive'"
          >
            {{ checkout['sell'].toLocaleString() }}
          </h5>
          <svg class="table__arrow" viewBox="0 0 512 512">
            <path
              d="M174.6 252.89l205.16 -205.16c7.2,-7.21 7.2,-18.89 0,-26.1 -7.2,-7.2 -18.88,-7.2 -26.09,0l-221.43 221.44c-7.2,7.2 -7.2,18.88 0,26.09l221.43 221.43c7.33,7.08 19.01,6.87 26.09,-0.46 6.91,-7.15 6.91,-18.48 0,-25.63l-205.13 -205.13 0 -0.03c-0.82,-0.83 -1.33,-1.97 -1.33,-3.23 0,-1.26 0.51,-2.4 1.33,-3.22l-0.03 0z"
            />
          </svg>
        </router-link>
        <i class="table__hr" />
        <router-link
          :to="{
            name: 'ReceiverTransaction',
            params: { id },
            query: { type: '5', checkout: checkoutId }
          }"
          class="table__row"
        >
          <h4 class="table__title">دریافت اعتبار خرید</h4>
          <h5
            class="table__amount amount--rial"
            :class="checkout['credit'] !== 0 && 'amount--positive'"
          >
            {{ checkout['credit'].toLocaleString() }}
          </h5>
          <svg class="table__arrow" viewBox="0 0 512 512">
            <path
              d="M174.6 252.89l205.16 -205.16c7.2,-7.21 7.2,-18.89 0,-26.1 -7.2,-7.2 -18.88,-7.2 -26.09,0l-221.43 221.44c-7.2,7.2 -7.2,18.88 0,26.09l221.43 221.43c7.33,7.08 19.01,6.87 26.09,-0.46 6.91,-7.15 6.91,-18.48 0,-25.63l-205.13 -205.13 0 -0.03c-0.82,-0.83 -1.33,-1.97 -1.33,-3.23 0,-1.26 0.51,-2.4 1.33,-3.22l-0.03 0z"
            />
          </svg>
        </router-link>
        <i class="table__hr" />
        <router-link
          :to="{
            name: 'ReceiverTransaction',
            params: { id },
            query: { type: '3', checkout: checkoutId }
          }"
          class="table__row"
        >
          <h4 class="table__title">دریافت بن خرید</h4>
          <h5
            class="table__amount amount--rial"
            :class="checkout['bon'] !== 0 && 'amount--positive'"
          >
            {{ checkout['bon'].toLocaleString() }}
          </h5>
          <svg
            class="table__arrow"
            width="512px"
            height="512px"
            viewBox="0 0 512 512"
          >
            <path
              d="M174.6 252.89l205.16 -205.16c7.2,-7.21 7.2,-18.89 0,-26.1 -7.2,-7.2 -18.88,-7.2 -26.09,0l-221.43 221.44c-7.2,7.2 -7.2,18.88 0,26.09l221.43 221.43c7.33,7.08 19.01,6.87 26.09,-0.46 6.91,-7.15 6.91,-18.48 0,-25.63l-205.13 -205.13 0 -0.03c-0.82,-0.83 -1.33,-1.97 -1.33,-3.23 0,-1.26 0.51,-2.4 1.33,-3.22l-0.03 0z"
            />
          </svg>
        </router-link>
        <i class="table__hr" />
        <router-link
          :to="{
            name: 'ReceiverTransaction',
            params: { id },
            query: { type: '6', checkout: checkoutId }
          }"
          class="table__row"
        >
          <h4 class="table__title">دریافت بونوس</h4>
          <h5
            class="table__amount amount--rial"
            :class="checkout['bonus'] !== 0 && 'amount--positive'"
          >
            {{ checkout['bonus'].toLocaleString() }}
          </h5>
          <svg class="table__arrow" viewBox="0 0 512 512">
            <path
              d="M174.6 252.89l205.16 -205.16c7.2,-7.21 7.2,-18.89 0,-26.1 -7.2,-7.2 -18.88,-7.2 -26.09,0l-221.43 221.44c-7.2,7.2 -7.2,18.88 0,26.09l221.43 221.43c7.33,7.08 19.01,6.87 26.09,-0.46 6.91,-7.15 6.91,-18.48 0,-25.63l-205.13 -205.13 0 -0.03c-0.82,-0.83 -1.33,-1.97 -1.33,-3.23 0,-1.26 0.51,-2.4 1.33,-3.22l-0.03 0z"
            />
          </svg>
        </router-link>
        <i class="table__hr" />
        <!-- <router-link
          :to="{
            name: 'ReceiverTransaction',
            params: { id },
            query: { type: '2', checkout: checkoutId }
          }"
          class="table__row"
        >
          <h4 class="table__title">دریافت آفر</h4>
          <h5
            class="table__amount amount--rial"
            :class="checkout['offer'] !== 0 && 'amount--positive'"
          >
            {{ checkout['offer'].toLocaleString() }}
          </h5>
          <svg class="table__arrow" viewBox="0 0 512 512">
            <path
              d="M174.6 252.89l205.16 -205.16c7.2,-7.21 7.2,-18.89 0,-26.1 -7.2,-7.2 -18.88,-7.2 -26.09,0l-221.43 221.44c-7.2,7.2 -7.2,18.88 0,26.09l221.43 221.43c7.33,7.08 19.01,6.87 26.09,-0.46 6.91,-7.15 6.91,-18.48 0,-25.63l-205.13 -205.13 0 -0.03c-0.82,-0.83 -1.33,-1.97 -1.33,-3.23 0,-1.26 0.51,-2.4 1.33,-3.22l-0.03 0z"
            />
          </svg>
        </router-link>
        <i class="table__hr" /> -->
      </div>
      <div class="table__row final--row">
        <h4 class="table__title">صندوق بسته شده</h4>
        <h5
          class="table__amount amount--rial"
          :class="checkout['total'] !== 0 && 'amount--positive'"
        >
          {{ checkout['total'].toLocaleString() }}
        </h5>
      </div>
    </section>
    <fixed-footer />
  </div>
</template>

<script>
import { computed, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import dateConvertor from '@composable/dateConvertor'

export default {
  name: 'ReceiverCheckoutShow',
  props: ['id', 'checkoutId'],
  setup(props) {
    // Necessary items from store
    const store = useStore()
    const pageStatus = computed(() => store.state.receiver.pageStatus)
    const checkout = computed(() => store.state.receiver.item)

    // Inital api call
    const getCheckout = () => {
      store.dispatch('receiver/fetchItem', {
        part: 'checkout',
        id: props.checkoutId
      })
    }
    getCheckout()

    // Clean the house
    onBeforeUnmount(() => store.dispatch('receiver/clearItem'))

    // import time
    const { localizeDate, getHour } = dateConvertor()

    return { pageStatus, checkout, localizeDate, getHour, getCheckout }
  }
}
</script>

<style lang="scss" scoped>
.cmp-wrapper {
  padding-top: 0;
}
// table block
.table {
  border: none;
  padding: 0 10px;
  margin: 0 0 8px;
  width: 96%;
  display: flex;
  border-radius: 15px;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.table__row {
  width: 100%;
  padding: 0;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr) 30px;
  height: 60px;
}
.table__title {
  justify-self: start;
  font-size: 14px;
  text-align: right;
  padding: 2px 5px 0 0;
  color: #111;
  margin: 0;
}
.table__amount {
  justify-self: end;
  line-height: 30px;
  font-size: 16px;
  text-align: center;
  color: #111;
  margin: 0 0 0 18px;
  padding-top: 3px;
  &.amount--rial {
    position: relative;
    &::after {
      color: #111;
      position: absolute;
      content: 'R';
      font-size: 10px;
      right: -8px;
      top: 0px;
    }
  }
  &.amount--positive {
    position: relative;
    &::before {
      color: #111;
      position: absolute;
      content: '+';
      font-size: 18px;
      left: -16px;
    }
  }
  &.amount--negative {
    @extend .amount--positive;
    &::before {
      content: '-';
      left: -14px;
      font-size: 20px;
    }
  }
}
.table__arrow {
  width: 20px;
  height: 20px;
  fill: #bbb;
  justify-self: end;
}
.final--row {
  width: calc(96% - 20px);
}

// cusotmer count
.receiver-count {
  border-radius: 15px 15px 0 0;
  padding: 0px 0 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .count__title {
    text-align: right;
    font-size: 16px;
    color: #222;
    margin: 0;
  }
  .count__number {
    text-align: right;
    font-size: 16px;
    margin: 0 5px 0 0;
    color: #222;
    &::after {
      content: 'نفر';
      margin-right: 5px;
      color: #333;
    }
  }
}

.checkout-time {
  border-radius: 15px;
  width: 100%;
  background-color: #ccc;
  border-radius: 0 0 30px 30px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0 20px;
  color: #444;
  h4 {
    margin-right: 10px;
  }
}
</style>
