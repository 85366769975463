<template>
  <div class="receiver-component-wrapper">
    <form novalidate class="form-wrapper" @submit.prevent="submitForm">
      <div class="form-wrapper" v-if="showForm">
        <our-input
          label="نام پذیرنده"
          width="90%"
          animateType="decrease"
          v-model="formItems['recName']"
          star-label
        />
        <base-input
          label="شماره همراه پذیرنده"
          input-type="number"
          v-model="formItems['recNumber']"
          start-left
          phone-number
        />
        <div class="sms" v-if="mode === 'edit'">
          <h4>
            دسترسی / <span>{{ userActiveText }}</span>
          </h4>
          <toggle-switch v-model="formItems['recActive']" />
        </div>
        <div class="sms" v-if="false">
          <h4>
            <span>{{ userReceiveSmsText }}</span> SMS تراکنش
          </h4>
          <toggle-switch v-model="formItems['recReceiveSms']" />
        </div>
      </div>
      <div class="packages" v-if="showPackages">
        <receiver-package
          v-for="pack in packages"
          :key="pack.id"
          :price-old="pack['price'] / 10"
          :price-new="pack['price_final'] / 10"
          :package-days="pack['days']"
          :package-name="pack['name']"
          :is-checked="formItems['recPackage'] == pack.id"
          @selectPackage="
            (val) => {
              val
                ? (formItems['recPackage'] = String(pack.id))
                : (formItems['recPackage'] = '')
            }
          "
        />
      </div>
      <div class="credit-buttons">
        <button type="submit" class="button button--black">
          <span class="button__text" v-text="confirmBtnText" />
          <spinner v-if="btnLoading" />
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import {
  reqString,
  schemaMaker,
  validateForms,
  reqMobile
} from '@valid/JoiValidation'
import {
  createReceiver,
  editReceiver,
  rechargeReceiver
} from '@service/ReceiverService'
import OurInput from '@common/OurInput'
import ToggleSwitch from '@common/ToggleSwitch'
import ReceiverPackage from './subComponents/ReceiverPackage'
import { computed, onBeforeUnmount, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

export default {
  name: 'ReceiverNew',
  components: {
    OurInput,
    ToggleSwitch,
    ReceiverPackage
  },
  props: ['mode', 'id'],
  setup(props) {
    // initial api call
    const router = useRouter()
    const store = useStore()
    store.dispatch('receiver/fetchReceiverPackages')
    const packages = computed(() => store.state.receiver.packages)
    if (props.mode === 'edit')
      store.dispatch('fetchItem', { part: 'receiver', id: props.id })

    // show packges
    const showPackages = computed(() => {
      return props.mode === 'new' || props.mode === 'recharge'
    })
    const showForm = computed(() => {
      return props.mode === 'new' || props.mode === 'edit'
    })

    // init forms
    const formItems = reactive({
      recNumber: '',
      recName: '',
      recActive: false,
      recReceiveSms: false,
      recPackage: ''
    })
    const btnLoading = ref(false)
    const isDone = ref(false)
    const receiver = computed(() => store.state.receiver.receiver)
    watch(receiver, (curr) => {
      if (curr) {
        formItems['recNumber'] = curr['mobile']
        formItems['recName'] = curr['name']
        formItems['recActive'] = curr['is_active']
        formItems['recReceiveSms'] = curr['sms']
      }
    })

    // submition
    const validNew = schemaMaker({
      name: reqString('نام پذیرنده'),
      mobile: reqMobile('شماره موبایل پذیرنده'),
      package: reqString('بسته', 'انتخاب "بسته" الزامی است.')
    })
    const validEdit = schemaMaker({
      name: reqString('نام پذیرنده'),
      mobile: reqMobile('شماره موبایل پذیرنده')
    })
    const validRecharge = schemaMaker({
      package: reqString('بسته', 'انتخاب "بسته" الزامی است.')
    })
    const submitForm = async () => {
      if (btnLoading.value || isDone.value) return
      const { recName: name, recNumber: mobile, recPackage: pack } = formItems

      // Validation
      let errors
      if (props.mode === 'new')
        errors = validateForms({ name, mobile, package: pack }, validNew)
      else if (props.mode === 'edit')
        errors = validateForms({ name, mobile }, validEdit)
      else if (props.mode === 'recharge')
        errors = validateForms({ package: pack }, validRecharge)
      else return

      // Show errors
      if (errors && errors.length > 0) {
        for (let error of errors) {
          const message = {
            type: 'error',
            message: error
          }
          store.dispatch('addToast', message)
        }
        return
      }

      // Init final form
      const finalForm = { name, mobile }
      // finalForm['sms'] = formItems['recReceiveSms'] ? 1 : 0
      finalForm['sms'] = 1
      finalForm['is_active'] = formItems['recActive'] ? 1 : 0
      finalForm['package_id'] = pack
      btnLoading.value = true
      switch (props.mode) {
        case 'new':
          try {
            await createReceiver(finalForm)
            store.dispatch('addToast', {
              type: 'success',
              message: 'پذیرنده‌ی جدید با موفقیت ایجاد شد.'
            })
            isDone.value = true
            store.dispatch('fetchHomeData')
            setTimeout(() => router.push({ name: 'ReceiverHome' }), 1500)
          } catch (ex) {
            if (ex.response) {
              switch (ex.response.status) {
                case 402:
                  store.dispatch('profile/handleCreditModal', {
                    status: 'open',
                    type: 'credit'
                  })
                  break
                default:
                  store.dispatch('addToast', {
                    type: 'error',
                    message: 'درخواست شما موفقیت آمیز نبود.'
                  })
              }
            }
          } finally {
            btnLoading.value = false
          }
          break

        case 'edit':
          finalForm['cashier_id'] = props.id
          try {
            await editReceiver(finalForm)
            store.dispatch('addToast', {
              type: 'success',
              message: 'تغییرات مورد نظر شما با موفقیت ثبت شد.'
            })
            isDone.value = true
            setTimeout(() => router.push({ name: 'ReceiverHome' }), 1500)
          } catch (ex) {
            if (ex.response) {
              store.dispatch('addToast', {
                type: 'error',
                message: 'درخواست شما موفقیت آمیز نبود.'
              })
            }
          } finally {
            btnLoading.value = false
          }
          break

        case 'recharge':
          try {
            await rechargeReceiver({ cashier_id: props.id, package_id: pack })
            store.dispatch('addToast', {
              type: 'success',
              message: 'بسته با موفقیت خریداری شد.'
            })
            isDone.value = true
            store.dispatch('fetchHomeData')
            setTimeout(
              () =>
                router.push({ name: 'ReceiverShow', params: { id: props.id } }),
              1500
            )
          } catch (ex) {
            if (ex.response) {
              switch (ex.response.status) {
                case 402:
                  store.dispatch('profile/handleCreditModal', {
                    status: 'open',
                    type: 'credit'
                  })
                  break
                default:
                  store.dispatch('addToast', {
                    type: 'error',
                    message: 'درخواست شما موفقیت‌آمیز نبود.'
                  })
              }
            }
          } finally {
            btnLoading.value = false
          }
          break
        default:
          btnLoading.value = false
          break
      }
    }
    // clean the room
    onBeforeUnmount(() => store.dispatch('receiver/clearItem'))

    return {
      packages,
      showPackages,
      showForm,
      formItems,
      btnLoading,
      receiver,
      submitForm
    }
  },
  computed: {
    userActiveText() {
      return this.formItems['recActive'] ? 'فعال' : 'غیرفعال'
    },
    userReceiveSmsText() {
      return this.formItems['recReceiveSms'] ? 'دریافت' : 'عدم دریافت'
    },
    confirmBtnText() {
      if (this.mode === 'new') {
        return 'ایجاد پذیرنده'
      } else if (this.mode === 'edit') {
        return 'تأیید'
      } else {
        return 'تأیید'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.receiver-component-wrapper {
  padding: 0;
  .sms {
    margin-top: 20px;
    width: 90%;
    border-radius: 15px;
    background-color: #e4e4e480;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
    h4 {
      font-size: 14px;
      // font-weight: bold;
      margin: 0;
    }

    &.title {
      margin-top: 0;
      margin-bottom: 20px;
      h5 {
        margin: 0;
        margin-left: 2px;
      }
    }
  }
}
.packages {
  margin: 20px 0 10px;
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: center;
}
</style>
