<template>
  <div class="cmp-wrapper">
    <navbar :msg="titleText" />
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading' && firstLoad"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <failed-mode v-else-if="pageStatus == 'failed'" @refresh="intersected" />
    <section class="load-resolved" v-else>
      <div class="cmp-items-wrapper">
        <receiver-transaction-single
          v-for="payment in transactions"
          :key="payment['id']"
          :status="payment['type']"
          :date="localizeDate(payment['created_at'])"
          :hour="getHour(payment['created_at'])"
          :price="payment['price']"
          :count="payment['count']"
          :total="payment['total']"
          :remain="payment['remain']"
          :customer="payment['name']"
          :wallet="payment?.['wallet']"
          :is-success="payment['status']"
          :relation="payment['relation_id']"
        />
      </div>
      <loader v-show="pageStatus == 'loading' && !firstLoad" />
      <observer @intersect="intersected" />
    </section>
    <fixed-footer />
  </div>
</template>

<script>
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import dateConvertor from '@composable/dateConvertor'
import ReceiverTransactionSingle from './subComponents/ReceiverTransactionSingle'

export default {
  name: 'ReceiverTransaction',
  components: {
    ReceiverTransactionSingle
  },
  props: ['id'],
  setup(props) {
    // Init store
    const route = useRoute()
    const store = useStore()
    const pageStatus = computed(() => store.state.receiver.pageStatus)
    const transactions = computed(() => store.state.receiver.backupItems)
    const getTransactions = () => {
      store.dispatch('receiver/fetchItems', {
        part: 'transaction',
        backup: true,
        query: {
          ...route.query,
          cashier: props.id
        }
      })
    }
    getTransactions()

    // Implementing intersection
    const intersected = () => {
      if (pageStatus.value == 'loading') return
      getTransactions()
    }

    // title text
    const titleText = computed(() => {
      const type = route.query?.type
      switch (type) {
        case '1':
          return 'ثبت فروش'
        case '6':
          return 'دریافت بونوس'
        case '2':
          return 'دریافت آفر'
        case '3':
          return 'دریافت بن خرید'
        case '5':
          return 'دریافت اعتبار خرید'
        default:
          return 'همه'
      }
    })

    // Handle two loadings
    const firstLoad = ref(true)
    watch(pageStatus, (curr) => {
      if (curr == 'resolved' || curr == 'failed') firstLoad.value = false
    })

    // Clear Trns
    onBeforeUnmount(() => store.dispatch('receiver/clearBackupItems'))

    const { localizeDate, getHour } = dateConvertor()

    return {
      pageStatus,
      intersected,
      transactions,
      localizeDate,
      getHour,
      titleText,
      firstLoad
    }
  }
}
</script>
