<template>
  <label>
    <input
      type="checkbox"
      @change="$emit('update', $event.target.checked)"
      :checked="isChecked"
    />
    <span class="checkmark"></span>
  </label>
</template>

<script>
export default {
  name: 'BaseCheckbox',
  props: {
    isChecked: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 23px;
  height: 23px;
  border-radius: 100%;
  overflow: hidden;
  background-color: #ddd;
}

input {
  opacity: 0;
  width: 0;
  height: 0;
}

.checkmark {
  position: relative;
  width: 15px;
  height: 15px;
  background: #dddddd;
  border-radius: 100%;
}

input:checked ~ .checkmark {
  background: #c69d4e;
}

input:checked ~ .checkmark:before {
  display: block;
}
</style>
