<template>
  <div class="single-receiver-wrapper" :class="[!isActive && 'dark']">
    <div class="receiver">
      <div class="receiver-title">
        <h1>پذیرنده {{ id }}</h1>
        <h2>{{ name }}</h2>
      </div>
      <div class="chest-container">
        <div class="chest-info">
          <h5>صندوق باز</h5>
          <h4>{{ total.toLocaleString() }}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReceiverSingle',
  props: {
    name: { type: String, default: 'نام پذیرنده' },
    id: Number,
    isActive: { type: Boolean, default: true },
    total: { type: Number, default: 0 }
  }
}
</script>

<style scoped lang="scss">
.single-receiver-wrapper {
  padding: 0;
  margin: 0 0 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .receiver {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 92%;
    // background-color: #fff;
    transition: background-color 0.3s ease;
    border-radius: 20px;
    padding: 0;

    .receiver-title {
      background-color: #f8f8f8;
      border-radius: 15px 15px 0 0;
      padding: 15px 10px 45px;
      margin-bottom: -30px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      h1 {
        text-align: right;
        font-size: 16px;
        margin: 1px 0 0;
        color: #777;
      }
      h2 {
        text-align: right;
        font-size: 16px;
        margin: 1px 10px 0 0;
        color: #777;
      }
      span {
        font-size: 16px;
        margin: 0 5px;
        color: #222;
        position: relative;
        top: 2px;
      }
    }
    .chest-container {
      width: 100%;
      padding: 20px 0;
      border-radius: 15px;
      margin: 0;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      .edit-btn {
        width: 100%;
        margin-top: 0px;
        background-color: #fff;
        padding: 10px 0;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        svg {
          width: 30px;
          height: 30px;
          margin: 0;
          position: relative;
          bottom: 5px;
          .fil0 {
            fill: #aaa;
          }
        }
        span {
          font-size: 14px;
          margin-right: 5px;
          // font-weight: bold;
          color: #aaa;
        }
      }
      i {
        align-self: center;
        width: 1px;
        height: 80%;
        background-color: #ddd;
      }
      .chest-info {
        grid-column: 3/6;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        h5 {
          color: #c69d4e;
          font-size: 20px;
          margin-bottom: 8px;
        }
        h4 {
          color: #000;
          position: relative;
          margin-right: 12px;
          font-size: 22px;
          &::after {
            position: absolute;
            content: 'R';
            right: -12px;
            top: 2px;
            font-size: 14px;
            margin-left: 8px;
            color: #888;
          }
        }
      }
      svg {
        cursor: pointer;
        margin: auto 0;
        width: 30px;
        height: 30px;
        .fil0 {
          fill: #c69d4e;
        }
      }
    }
  }

  &.dark {
    .receiver .receiver-title {
      background-color: #181818;
    }
    .receiver .receiver-title h1 {
      color: #555;
      font-weight: normal;
    }
    .receiver .receiver-title h2 {
      color: #555;
    }
    .receiver .chest-container {
      background-color: #131313;
      i {
        background-color: #333;
      }
      .chest-info h4 {
        color: #fff;
      }
      .chest-info h5 {
        color: #444;
      }
      svg .fil0 {
        fill: #444;
      }
      .chest-info h4 {
        color: #444;
      }
      .chest-info h4::after {
        color: #444;
      }
      .edit-btn {
        background-color: #131313;
        box-shadow: none;
      }
      .edit-btn span {
        color: #444;
      }
      .edit-btn svg .fil0 {
        fill: #444;
      }
    }
  }
}
</style>
