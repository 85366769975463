<template>
  <div class="package" @click.self="handleClick">
    <h5 class="package__days" v-text="packageName" />
    <div class="package__prices">
      <h3
        class="package__price price--old"
        v-text="priceOld.toLocaleString()"
      />
      <h3
        class="package__price price--new"
        v-text="priceNew.toLocaleString()"
      />
    </div>
    <div class="package__input">
      <base-checkbox
        ref="checkbox"
        @update="
          (val) => {
            $emit('select-package', val)
          }
        "
        :isChecked="isChecked"
      />
    </div>
  </div>
</template>

<script>
import BaseCheckbox from '@common/BaseCheckbox'

export default {
  name: 'ReceiverPackage',
  components: {
    BaseCheckbox
  },
  props: {
    priceOld: {
      type: [String, Number],
      default: 0
    },
    priceNew: {
      type: [String, Number],
      default: 0
    },
    isChecked: {
      type: Boolean,
      default: false
    },
    packageDays: {
      type: [String, Number],
      default: 0
    },
    packageName: {
      type: String
    }
  },
  methods: {
    handleClick() {
      this.$refs['checkbox'].$el.click()
    }
  }
}
</script>

<style lang="scss" scoped>
.package {
  width: 100%;
  height: 80px;
  display: grid;
  border-radius: 10px;
  grid-template-columns: 150px 1fr 50px;
  background-color: #fff;
  margin-bottom: 5px;
  cursor: pointer;
}
.package__prices {
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.package__price {
  position: relative;
  font-size: 16px;
  &::after {
    content: 'تومان';
    font-size: 12px;
    bottom: 2px;
    position: relative;
    margin-right: 3px;
  }
}
.price--old {
  color: #777;
  margin-bottom: 4px;
  &::before {
    content: '';
    position: absolute;
    width: 120%;
    background-color: rgba(0, 0, 0, 0.3);
    height: 2px;
    top: 0;
    bottom: 6px;
    left: -10%;
    margin: auto 0;
  }
}
// .price--new {
// color: #c69d4e;
// }
.package__input {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.package__days {
  pointer-events: none;
  direction: rtl;
  width: 100%;
  text-align: right;
  padding-right: 15px;
  font-size: 18px;
  height: 100%;
  line-height: 80px;
}
.package__days::after {
  // content: 'روز';
  color: #aaa;
  position: relative;
  bottom: 3px;
  margin-right: 3px;
}
</style>
