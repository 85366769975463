<template>
  <div class="checkout">
    <h4>
      {{ localizeDate(time) }}
    </h4>
    <h5>
      {{ getHour(time) }}
    </h5>
  </div>
</template>

<script>
import dateConvertor from '@composable/dateConvertor'
export default {
  name: 'ReceiverCheckoutSingle',
  props: {
    time: String
  },
  setup() {
    const { localizeDate, getHour } = dateConvertor()

    return { localizeDate, getHour }
  }
}
</script>

<style lang="scss" scoped>
.checkout {
  background-color: #fff;
  border-radius: 15px;
  width: 96%;
  height: 100px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  // justify-content: space-between;
  padding: 0 20px;
  cursor: pointer;
  h4 {
    margin-bottom: 5px;
  }
  h5 {
    color: #999;
  }
}
</style>
