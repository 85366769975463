<template>
  <section class="list">
    <receiver-single
      v-for="receiver in receivers"
      :key="receiver['id']"
      :id="receiver['cashier_id']"
      :name="receiver['name']"
      :total="receiver['total']"
      :is-active="receiver['is_active']"
      @click="enterReceiver(receiver['id'])"
    />
    <failed-mode @refresh="intersected" v-if="pageStatus == 'failed'" />
    <loader v-if="pageStatus == 'loading'" />
    <observer @intersect="intersected" />
  </section>
</template>

<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { computed, onBeforeUnmount } from 'vue'
import ReceiverSingle from './subComponents/ReceiverSingle'

export default {
  name: 'ReceiverHome',
  components: {
    ReceiverSingle
  },
  setup() {
    // Fetch Receivers
    const store = useStore()
    store.dispatch('receiver/fetchItems', { part: 'cashier' })
    const receivers = computed(() => store.state.receiver.items)
    const pageStatus = computed(() => store.state.receiver.pageStatus)

    // Implementing intersection
    const intersected = () => {
      if (pageStatus.value == 'loading') return
      store.dispatch('receiver/fetchItems', { part: 'cashier' })
    }

    // Routing for entering offers
    const router = useRouter()
    const enterReceiver = (id) => {
      router.push({ name: 'ReceiverShow', params: { id } })
    }

    // Clear offers
    onBeforeUnmount(() => store.dispatch('receiver/clearItems'))

    // ------- //
    return { receivers, pageStatus, intersected, enterReceiver }
  }
}
</script>
