<template>
  <div class="single-sats-wrapper">
    <div class="sats" @click="expandPanel">
      <div class="sats-info">
        <span class="right-line" />
        <div class="right-title">
          <!-- <h2 v-text="titleText" /> -->
          <div class="time">
            <p><span v-text="date" />-<span v-text="hour" /></p>
          </div>
        </div>
        <h3 :class="valueSign" v-text="selectedValue" />
      </div>
      <div class="down-btn" v-show="accordionOpen" @click="expandPanel">
        <svg
          @click="expandPanel"
          viewBox="0 0 451.847 451.847"
          :class="{ open: accordionOpen }"
          :fill="darkMode ? '#8a8a8a' : '#999'"
        >
          <path
            d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
		c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
		c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"
          />
        </svg>
      </div>
      <div class="bottom-sep" :class="{ plain: accordionOpen }">
        <span />
      </div>
    </div>
    <div
      class="accordion-1"
      :class="{ open: accordionOpen, close: !accordionOpen }"
    >
      <div
        class="accordion-2"
        :class="{
          open: accordionOpen2,
          close: !accordionOpen2
        }"
      >
        <div class="sats-details">
          <div class="trans-info">
            <div class="table">
              <div class="stat-row" @click="enterCustomer">
                <h5>دریافت کننده</h5>
                <h6 v-text="customer" />
              </div>
            </div>
          </div>
        </div>
        <div class="sep" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReceiverTransactionSingle',
  props: {
    status: [String, Number],
    hour: {
      type: String
    },
    date: {
      type: String
    },
    remain: {
      type: [String, Number]
    },
    price: {
      type: [String, Number]
    },
    customer: {
      type: String,
      default: ''
    },
    wallet: {
      type: [String, Number]
    },
    isSuccess: {
      type: [String, Number]
    }
  },
  data() {
    return {
      darkMode: false,
      accordionOpen: false,
      accordionOpen2: false
    }
  },
  methods: {
    changeTheme(state) {
      this.darkMode = !state
    },
    expandPanel() {
      this.$emit('expand')
      this.accordionOpen = !this.accordionOpen
      if (this.accordionOpen) {
        setTimeout(() => {
          const fixedNavHeight = 50
          const { bottom } = this.$el.getBoundingClientRect()
          const limit = window.innerHeight - fixedNavHeight - bottom
          if (limit < 0) {
            window.scrollBy({ top: -limit, behavior: 'smooth' })
          }
        }, 200)
      }
      this.accordionOpen2 = !this.accordionOpen2
    },
    enterCustomer() {
      if (!this.wallet) return
      this.$router.push({ name: 'CustomerShow', params: { id: this.wallet } })
    }
  },
  computed: {
    titleText() {
      switch (this.status) {
        case 1:
          return 'ثبت فروش'
        case 2:
          return 'دریافت آفر'
        case 3:
          return 'دریافت بن خرید'
        case 6:
          return 'دریافت بونوس'
        case 5:
          return 'دریافت اعتبار خرید'
        default:
          return ''
      }
    },
    selectedValue() {
      switch (this.status) {
        case 1:
          return this.price?.toLocaleString()
        case 2:
          return this.price?.toLocaleString()
        case 3:
          return this.price?.toLocaleString()
        case 4:
          return this.price?.toLocaleString()
        case 5:
          return this.price?.toLocaleString()
        default:
          return this.price?.toLocaleString()
      }
    },
    valueSign() {
      switch (this.status) {
        case 1:
          return 'plus'
        case 2:
          return 'plus'
        case 3:
          return 'plus'
        case 4:
          return 'minus'
        case 5:
          return 'plus'
        case 6:
          return 'plus'
        default:
          return 'minus'
      }
    }
  }
}
</script>

<style scoped lang="scss">
div.single-sats-wrapper {
  padding: 0 0 0 0;
  margin: 0 0 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  div.remaining-credit {
    height: 35px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 25px;
    background-color: #ddd;
    h5 {
      font-size: 16px;
      height: 100%;
      line-height: 35px;
      margin: 0;
      color: #777;
    }
    h6 {
      padding-top: 2px;
      position: relative;
      color: #777;
      height: 100%;
      line-height: 35px;
      margin: 0;
      &::after {
        position: absolute;
        content: 'R';
        color: #777;
        font-size: 10px;
        right: -8px;
        top: -2px;
      }
    }
  }

  div.sats {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    width: 100%;
    background-color: #fff;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    // box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);

    .sats-info {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px 0 25px;
      .right-line {
        background-color: #777777;
        position: absolute;
        margin-left: 10px;
        width: 5px;
        height: 98%;
        top: 0px;
        bottom: 0;
        margin: auto 0;
        right: 0;
        z-index: 2;
        border-radius: 5px 0 0 5px;
        // &.minus {
        //   background-color: #d40000;
        // }
        // &.plus {
        //   background-color: #00c209;
        // }
        // &.gray {
        //   background-color: #777;
        // }
      }
      .right-title {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        h2 {
          position: relative;
          font-size: 16px;
          margin: 0;
          color: #1f1f1f;
        }
        .time {
          direction: ltr;
          font-size: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 3px;
          color: #777;

          p {
            // height: 100%;
            // line-height: 40px;
            margin: 0;
            padding: 0;
            word-spacing: 5px;
            // font-weight: bold;
            padding-top: 2px;
            span {
              color: #aaa;
            }
          }
        }
      }

      h3 {
        direction: ltr;
        text-align: left;
        position: relative;
        font-size: 18px;
        // font-weight: bold;
        margin: 0;
        padding-top: 6px;
        color: #333;
        &::before {
          position: absolute;
          left: -12px;
        }
        &::after {
          position: absolute;
          content: 'R';
          // color: #aaa;
          font-size: 10px;
          right: -10px;
          top: 6px;
        }
        &.plus {
          // color: #00c209;
        }
        &.minus {
          color: #d40000;
        }
        &.plus::before {
          content: '+';
        }
        &.minus::before {
          content: '-';
        }
      }
    }

    .bottom-sep {
      height: 1px;
      padding: 0;
      margin: 0;
      position: relative;
      overflow: hidden;
      border-radius: 0;
      background-color: #fff;
      width: 100%;
      transition: background-color 0.3s ease;
      &.plain {
        border-radius: 0;
      }
      span {
        position: absolute;
        transition: all 0.3s ease;
        background-color: #e0e0e0;
        height: 1px;
        width: 100%;
        margin: 0 auto;
        right: 0;
        left: 0;
        bottom: 0px;
      }
    }

    div.down-btn {
      position: absolute;
      cursor: pointer;
      width: 45px;
      height: 45px;
      right: 0;
      left: 0;
      margin: 0 auto;
      bottom: -15px;
      border-radius: 50%;
      background-color: #fff;
      z-index: 1;
      transform: scaleX(1.5);
      box-shadow: 0px 7px 6px -2px rgba(0, 0, 0, 0.2);
      transition: background-color 0.3s ease;
      &.dark {
        background-color: rgb(31, 31, 31);
      }

      svg {
        width: 100%;
        height: 100%;
        transition: all 0.3s ease;
        transform: scale(0.33, 0.5);
        &.open {
          transform: rotate(180deg) scale(0.33, 0.5);
        }
      }
    }
  }

  div.accordion-1 {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border-radius: 0;
    margin: 0 0 0;
    padding: 0;
    max-height: 0;
    &.open {
      max-height: 700px;
    }
    &.close {
      max-height: 0;
    }
    .accordion-2 {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      margin: 0 0 0;
      padding: 0;
      background-color: #1a1a1a;
      max-height: 700px;
      &.open {
        opacity: 1;
      }
      &.close {
        opacity: 0;
      }
      .sats-details {
        width: 100%;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 0 10px 20px;

        p {
          text-align: justify;
          color: #8a8a8a;
          padding: 0 10px;
          margin-bottom: 0;
        }
        .trans-info {
          width: 94%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          padding: 20px 0;
          div.table {
            margin: 17px 0 0;
            width: 100%;
            border: 1px solid rgba(138, 138, 138, 0.3);
            display: flex;
            align-items: center;
            flex-direction: column;
            border-radius: 10px;
          }
          div.stat-row {
            padding: 0 4%;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #8a8a8a;
            h5 {
              font-size: 14px;
              margin: 0;
              // font-weight: bold;
            }
            h6 {
              font-size: 16px;
              margin-bottom: 0;
              // font-weight: bold;
              padding: 13px 0 11px;
              direction: ltr;
              text-align: left;
              position: relative;
              // color: #8a8a8a;
              &.minus {
                color: #d40000;
              }
              &.minus::before {
                position: absolute;
                left: -12px;
                content: '-';
              }
              &.rial::after {
                position: absolute;
                content: 'R';
                color: #8a8a8a;
                font-size: 10px;
                right: -10px;
                top: 13px;
              }
              &.percent::after {
                content: '%';
                color: #8a8a8a;
                top: 0px;
                left: 3px;
                position: relative;
              }
            }
          }
          hr {
            width: 100%;
            margin: 0;
            border-top: 1px solid rgba(138, 138, 138, 0.3);
            border-radius: 10px;
            transition: border-top 0.3s ease;
          }
          div.offer-type {
            background-color: #1a1a1a;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 40px 0 10px;
            padding: 0;
            img {
              border-radius: 10px;
              width: 200px;
              height: 160px;
              box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.1);
            }
            h4 {
              color: white;
              font-size: 16px;
              margin: 15px 0 10px 0;
            }
          }
        }
      }
      .sep {
        width: 100%;
        height: 1px;
        background-color: #fff;
      }
    }
  }
}
</style>
