<template>
  <div class="cmp-wrapper">
    <navbar msg="صندوق های بسته شده" />
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading' && firstLoad"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <failed-mode v-else-if="pageStatus == 'failed'" @refresh="intersected" />
    <section class="load-resolved" v-else>
      <div class="cmp-items-wrapper">
        <receiver-checkout-single
          v-for="checkout in checkouts"
          :key="checkout['id']"
          :time="checkout['created_at']"
          @click="enterCheckout(checkout['id'])"
        />
      </div>
      <div class="cmp-items--empty" v-if="checkouts.length === 0">
        صندوقی بسته نشده است.
      </div>
      <loader v-show="pageStatus == 'loading' && !firstLoad" />
      <observer @intersect="intersected" />
    </section>
    <fixed-footer />
  </div>
</template>

<script>
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import dateConvertor from '@composable/dateConvertor'
import ReceiverCheckoutSingle from './subComponents/ReceiverCheckoutSingle'

export default {
  name: 'ReceiverCheckout',
  props: ['id'],
  components: {
    ReceiverCheckoutSingle
  },
  setup(props) {
    // Init store
    const store = useStore()
    const checkouts = computed(() => store.state.receiver.backupItems)
    store.dispatch('receiver/fetchItems', {
      part: 'checkout',
      backup: true,
      query: { cashier: props.id }
    })

    // Implementing intersection
    const pageStatus = computed(() => store.state.receiver.pageStatus)
    const intersected = () => {
      if (pageStatus.value == 'loading') return
      store.dispatch('receiver/fetchItems', {
        part: 'checkout',
        backup: true,
        query: { cashier: props.id }
      })
    }

    // Enter checkout
    const router = useRouter()
    const enterCheckout = (id) => {
      router.push({ name: 'ReceiverCheckoutShow', params: { checkoutId: id } })
    }

    // Handle two loadings
    const firstLoad = ref(true)
    watch(pageStatus, (curr) => {
      if (curr == 'resolved' || curr == 'failed') firstLoad.value = false
    })

    // Clean the room
    onBeforeUnmount(() => store.dispatch('receiver/clearBackupItems'))

    const { localizeDate, getHour } = dateConvertor()

    return {
      pageStatus,
      intersected,
      checkouts,
      localizeDate,
      getHour,
      enterCheckout,
      firstLoad
    }
  }
}
</script>
